$(document).ready(function() {

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 25) {
            $('body').addClass('scrolled')
        } else {
            $('body').removeClass('scrolled')
        }
    })

    if ($(window).scrollTop() > 25) {
        $('body').addClass('scrolled')
    } else {
        $('body').removeClass('scrolled')
    }

    $('.js-service_header').on('click', function() {
        $(this).toggleClass('active')
        $(this).siblings('.js-service_body').slideToggle()
    })

    var casesEmpresarialSwiper = new Swiper('.swiper-container--cases-empresarial', {
        autoHeight: true,
        spaceBetween: 50,
        speed: 600,
        pagination: {
            el: '.swiper-pagination--cases-empresarial',
            type: 'bullets',
            clickable: true,
        },
    });

    var casesResidencialSwiper = new Swiper('.swiper-container--cases-residencial', {
        autoHeight: true,
        spaceBetween: 50,
        speed: 600,
        pagination: {
            el: '.swiper-pagination--cases-residencial',
            type: 'bullets',
            clickable: true,
        },
    });

})

$(window).on('load', function() {
    $.ready.then(function() {

        var swiperCases = new Swiper('.swiper-cases', {
            slidesPerView: 5,
            autoplay: {
                delay: 1500,
            },
            speed: 800,
            loop: true,
            simulateTouch: false,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                500: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 5,
                },
            },
        })

        // Magnific Popup
        $('.js-case_photos').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                // titleSrc: function(item) {
                //     return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
                // }
            },
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            }
        });

        $('.js-case_photos--residencial').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                // titleSrc: function(item) {
                //     return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
                // }
            },
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            }
        });

        var feedbacksSwiper = new Swiper('.swiper-container--feedbacks', {
            spaceBetween: 75,
            speed: 1000,
            autoplay: {
                delay: 3000
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            simulateTouch: false,
        })

        $('.swiper-container--feedbacks').hover(function() {
            feedbacksSwiper.autoplay.stop()
        }, function() {
            feedbacksSwiper.autoplay.start()
        })

        $('.js-mobile-nav-toggler').on('click', function() {
            $('.navbar--mobile').slideToggle()

            if ($(this).html() == '<i class="ri ri-menu-line"></i>') {
                $(this).html('<i class="ri ri-close-line"></i>')
            } else {
                $(this).html('<i class="ri ri-menu-line"></i>')
            }
        })

        // Phone mask
        var SPMaskBehavior = function(val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009'
        },
        spOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options)
            }
        }
        $('[name=phone]').mask(SPMaskBehavior, spOptions)

        $('.js-contact-form').on('submit', function(e) {
            e.preventDefault()

            var $this = $(this)
            var $submitBtn = $this.find('[type=submit]')
            var submitBtnLabel = $submitBtn.html()

            $.ajax({
                url: $this.attr('action'),
                type: $this.attr('method'),
                data: new FormData($this[0]),
                cache: false,
                processContent: false,
                contentType: false,
                processData: false,
                dataType: 'json',
                beforeSend: function() {
                    $submitBtn.prop('disable', true)
                    $submitBtn.html('Enviando...')
                },
                success: function(res) {
                    if (res[0]) {
                        $this[0].reset()
                    }

                    return new NotifyIt({
                        // Message to display.
                        'message': res[1],
                        // Status of the displayed message.
                        'status': (res[0]) ? 'success' : 'error',
                        // Duration of the alert. 0 to keep it.
                        'duration': 5
                    })
                },
                error: function(xhr, status, error) {
                    return new NotifyIt({
                        'message': error,
                        'status': 'error',
                        'duration': 5
                    })
                },
                complete: function() {
                    $submitBtn.prop('disable', false)
                    $submitBtn.html(submitBtnLabel)
                }
            })
        })

    })
})
